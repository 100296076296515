//Based on https://github.com/leighhalliday/gist-shameful/tree/main/src
import { StyledFirebaseAuth } from "react-firebaseui";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { green } from '@mui/material/colors';

import { firebase } from "./initFirebase";

const firebaseAuthConfig = {
    signInFlow: "popup",
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
};

const theme = createTheme();

const FirebaseAuth = () => {
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar src="/icons8-pencil-drawing-96.png" sx={{ width: 96, height: 96, bgcolor: theme.palette.primary.main }} />
                    <Typography component="h1" variant="h3">
                        Welcome to PopSmartWrite!
                    </Typography>
                    <Typography component="p">
                        Please sign in to proceed.
                    </Typography>
                    <Paper sx={{ p: 10, display: 'flex', flexDirection: 'column' }}>
                        <StyledFirebaseAuth uiConfig={firebaseAuthConfig} firebaseAuth={firebase.auth()} />
                    </Paper>
                </Box>
            </Container>

        </ThemeProvider>

    );
};

export default FirebaseAuth;
