import * as React from 'react';
import { useEffect } from 'react';
import WritingPad from './WritingPad';
import FirebaseAuth from "./firebaseAuth";
import { useAuth } from "./authProvider";
import { getDatabase, ref, set } from "firebase/database";

export default function App() {
  const { user, loading } = useAuth();
  // console.info(user, loading);

  useEffect(() => {
    if (!!user) {
      //Save user credential to DB
      const lastVisited = new Date().toISOString(); //new Date().toISOString().substr(0,10)
      const db = getDatabase();
      const { uid, displayName, photoURL, email } = user;
      const payload = { uid, displayName, photoURL, email, lastVisited };
      console.info('Current user', payload);
      set(ref(db, 'users/' + user.uid + '/info'), payload);
    }
  })

  if (loading) return null;
  if (!user) return <FirebaseAuth />;

  return (
    <WritingPad />
  );
}
