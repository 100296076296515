import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import CreateIcon from '@mui/icons-material/Create';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import PhoneIcon from '@mui/icons-material/Phone';
import { green } from '@mui/material/colors';
import Copyright from './Copyright';
import { getDatabase, ref, update, onValue } from "firebase/database";
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import WriterLexical from './WriterLexical';
import ListOfStories from './ListOfStories';
import ListOfReviewStories from './ListOfReviewStories';
import { useAuth } from "./authProvider";
import { checkSubscription } from "./SubscriptionManager";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const mdTheme = createTheme();

function WritingPadContent() {
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => { setOpen(!open); };
    const { user, logout } = useAuth();
    const [notification, setNotification] = useState(null);
    const [story, setStory] = useState(null);
    const [coaches, setCoaches] = useState([]);
    const [valueTab, setValueTab] = React.useState('0');
    const [userCoach, setUserCoach] = useState(false);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const db = getDatabase();

    //load Coaches
    useEffect(() => {
        console.info("Loading coaches");

        const userCoachRef = ref(db, `/coaches/${user.uid}`);
        const unsubscriberFun = onValue(userCoachRef, (userCoachSnap) => {
            console.info("userCoachSnap", userCoachSnap.val());
            if (userCoachSnap.exists()) {
                setUserCoach(userCoachSnap.val());
            }
        });

        const coachesRef = ref(db, `/coaches`);
        onValue(coachesRef, (coachesSnap) => {
            console.info("coachesSnap", coachesSnap.val());
            if (coachesSnap.exists()) {
                setCoaches([]);
                for (const [key, value] of Object.entries(coachesSnap.val())) {
                    console.info("Coach", key, value);
                    if (value) {
                        const coachRef = ref(db, `/users/${key}/info`);
                        onValue(coachRef, (coachSnap) => {
                            const coach = { uid: key, ...coachSnap.val() };
                            console.info("Coach:", coach);
                            //https://stackoverflow.com/questions/58193166/usestate-hook-setter-incorrectly-overwrites-state
                            setCoaches((prevCoaches) => ([coach, ...prevCoaches]));
                        }, { onlyOnce: true });
                    }
                }
            }
        }, { onlyOnce: true });

        return (unsubscriberFun);
    }, []);

    const submitStoryForReview = (record, coachUid) => {
        console.info("SUBMIT", record, user);

        if (record && record.status !== "In-Review") {
            checkSubscription(user, () => {
                const coach = coaches.find(({ uid }) => uid === coachUid);
                if (coach && confirm(`Are you sure you want to submit story, coach ${coach.displayName} for review?`)) {
                    const updates = {};
    
                    updates[`/stories/${record.id}/reviewer`] = coachUid;
                    const timeStamp = new Date().toISOString();
                    updates[`/stories/${record.id}/sentForReviewDate`] = timeStamp;
                    updates[`/stories/${record.id}/lastUpdated`] = timeStamp;
                    updates[`/stories/${record.id}/status`] = "In-Review";
                    updates[`/users/${coachUid}/reviewStories/${record.id}`] = true;
    
                    return update(ref(db), updates)
                        .then(() => {
                            setNotification(`Submitted story, "${record.storyName}" for review to ${coach.displayName}`);
                        })
                        .catch((error) => {
                            setNotification(`Error submitting story: "${record.storyName}"`);
                            console.error(`Error submitting story: "${record.storyName}"`, error);
                        });
                } else {
                    if (!coach) {
                        alert(`Not a valid coach ${coachUid}`);
                        console.error(`Not a valid coach ${coachUid}`);
                    }
                }
            });
        } else {
            alert(`Your story "${record.storyName}" is already in review. Please wait for ${coach.displayName} to return it.`);
        }
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton edge="start" color="inherit" sx={{ marginRight: '36px' }}>
                        {/* <IconButton edge="start" color="inherit" aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        > */}
                            {/* <CreateIcon /> */}
                            <Avatar src="/icons8-pencil-drawing-96.png" sx={{ width: 42, height: 42 }} />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h4"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            PopSmartWrite
                        </Typography>
                        {/* <IconButton color="inherit" sx={{ marginRight: '5px' }} >
                            <Badge badgeContent={5} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        <Typography component="span" color="inherit" noWrap sx={{ marginRight: '5px' }}
                        >
                            {`Welcome, ${user.displayName || user.phoneNumber}`}
                        </Typography>
                        {user.photoURL !== null &&
                            <Avatar src={user.photoURL} />
                        }
                        {user.photoURL == null && user.displayName != null &&
                            <Avatar {...stringAvatar(user.displayName)} />
                        }
                        {user.photoURL == null && user.phoneNumber != null &&
                            <Avatar sx={{ bgcolor: green[500] }}>
                                <PhoneIcon />
                            </Avatar>
                        }
                        <IconButton color="inherit" onClick={logout}>
                            <LogoutIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {/* <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <ListItemButton>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Class" />
                        </ListItemButton>
                        <Divider sx={{ my: 1 }} />
                        <ListSubheader component="div" inset>
                            My Stories
                        </ListSubheader>
                        <ListItemButton>
                            <ListItemIcon>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="My Adventures in..." />
                        </ListItemButton>
                    </List>
                </Drawer> */}
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="100%" sx={{ mt: 2, mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Recent Orders */}
                            <Grid item xs={12}>
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    {story == null && userCoach &&
                                        <Box sx={{ width: '100%', typography: 'body1' }}>
                                            <TabContext value={valueTab}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList onChange={handleChangeTab}>
                                                        <Tab label="My Stories" value="0" />
                                                        <Tab label="Review Stories" value="1" />
                                                    </TabList>
                                                </Box>
                                                <TabPanel value="0">
                                                    <ListOfStories
                                                        editStory={(s) => { setStory(s); }}
                                                        coaches={coaches}
                                                        submitStoryForReview={submitStoryForReview}
                                                    />
                                                </TabPanel>
                                                <TabPanel value="1">
                                                    <ListOfReviewStories
                                                        editStory={(s) => { setStory(s); }}
                                                        coaches={coaches}
                                                        submitStoryForReview={submitStoryForReview}
                                                    />
                                                </TabPanel>
                                            </TabContext>
                                        </Box>
                                    }
                                    {story == null && !userCoach &&
                                        <ListOfStories
                                            editStory={(s) => { setStory(s); }}
                                            coaches={coaches}
                                            submitStoryForReview={submitStoryForReview}
                                        />
                                    }
                                    {story != null &&
                                        <WriterLexical
                                            story={story}
                                            back={() => {
                                                setStory(null);
                                            }}
                                            coaches={coaches}
                                            submitStoryForReview={submitStoryForReview}
                                        />
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
            <Snackbar
                open={notification != null}
                autoHideDuration={2000}
                onClose={() => {
                    setNotification(null);
                }}
                message={notification}
            />
        </ThemeProvider>
    );
}

export default function WritingPad() {
    return <WritingPadContent />;
}
