//Based on https://github.com/leighhalliday/gist-shameful/tree/main/src
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
//import "firebase/compat/database";

const firebaseConfig = {
    apiKey: "AIzaSyDJRgjx7skWY8h2_Val-1MFT02qLutMM9Q",
    authDomain: "popsmartwrite-v2.firebaseapp.com",
    databaseURL: "https://popsmartwrite-v2-default-rtdb.firebaseio.com",
    projectId: "popsmartwrite-v2",
    storageBucket: "popsmartwrite-v2.appspot.com",
    messagingSenderId: "1015962477919",
    appId: "1:1015962477919:web:e3fe22a8c7d299ce6342ea",
    measurementId: "G-NKG4TGEL5C"
};

function initFirebase() {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }
}

initFirebase();

export { firebase };
