import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from "./authProvider";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import App from './App';
import reportWebVitals from './reportWebVitals';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDJRgjx7skWY8h2_Val-1MFT02qLutMM9Q",
//   authDomain: "popsmartwrite-v2.firebaseapp.com",
//   projectId: "popsmartwrite-v2",
//   storageBucket: "popsmartwrite-v2.appspot.com",
//   messagingSenderId: "1015962477919",
//   appId: "1:1015962477919:web:e3fe22a8c7d299ce6342ea",
//   measurementId: "G-NKG4TGEL5C"
// };

// Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
// // Initialize Firebase Authentication and get a reference to the service
// const auth = getAuth(firebaseApp);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
