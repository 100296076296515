//Based on https://github.com/leighhalliday/gist-shameful/tree/main/src
//and https://github.com/mui/material-ui/tree/v5.11.2/docs/data/material/getting-started/templates/sign-in
import {
    useEffect,
    useState,
    useContext,
    createContext,
} from "react";

import { firebase } from "./initFirebase";

const AuthContext = createContext({
    user: null,
    loading: true,
    logout: () => { },
});

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {//onAuthStateChanged OR onIdTokenChanged
        const cancelAuthListener = firebase.auth().onAuthStateChanged((u) => {
            setUser(u);
            setLoading(false);
        });

        return () => cancelAuthListener();
    }, []);

    return (
        <AuthContext.Provider
            value={{ user, loading, logout: () => firebase.auth().signOut() }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth() {
    return useContext(AuthContext);
}

export { AuthProvider, useAuth };
