import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import md5 from 'md5';
import { parseISO, format } from 'date-fns';
import { getDatabase, ref as ref_database, update, onValue } from "firebase/database";
import { getStorage, ref as ref_storage, uploadString, getDownloadURL } from "firebase/storage";

import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/material/styles';
import GradingIcon from '@mui/icons-material/Grading';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RateReviewIcon from '@mui/icons-material/RateReview';

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ImagesPlugin from './plugins/ImagesPlugin';
import { ImageNode } from './nodes/ImageNode';

import { checkSubscription } from "./SubscriptionManager";
import SelectCoachDialog from './SelectCoachDialog';
import { useAuth } from "./authProvider";

function formatDate(dateStr) {
    return (format(parseISO(dateStr), 'M/d/y h:mm:ss aaa'));
}

const storage = getStorage();
const db = getDatabase();

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'gray',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'gray',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'gray',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'gray',
        },
    },
    '& .MuiOutlinedInput-input': {
        fontSize: 32,
    },
});

export default function WriterLexical(props) {
    const { user } = useAuth();
    const [story, setStory] = useState(props.story);
    const [storyName, setStoryName] = useState(props.story.storyName);
    const [jsonHash, setJsonHash] = useState("");
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [openCoachSelection, setOpenCoachSelection] = useState(false);
    const [record, setRecord] = useState(null);
    const editorRef = useRef();

    const coach = (props.coaches && props.story.reviewer) ?
        props.coaches.find(({ uid }) => uid === props.story.reviewer) :
        null;

    const isEditableContent = (s) => (user.uid == s.uid && s.status === "Draft") || (user.uid == s.reviewer && s.status === "In-Review");

    const theme = useTheme();
    const editorConfig = {
        // The editor theme
        theme,
        namespace: "popsmartwrite",
        editable: false, //isEditableContent(story),
        // Handling of errors during update
        onError(error) {
            console.error(error);
            throw error;
        },
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            ImageNode,
        ]
    };

    //based on https://github.com/facebook/lexical/discussions/1937
    function FirebaseStoragePlugin() {
        const [editor] = useLexicalComposerContext();

        useEffect(() => {
            if (isFirstRender) {
                console.info("FirebaseStoragePlugin::init");

                const storageRef = ref_storage(storage, `stories/${story.id}.json`);

                //md5 digest
                const editorState = editor.getEditorState();
                const jsonDocumentCurrent = JSON.stringify(editorState.toJSON());
                const currentHash = md5(jsonDocumentCurrent);
                //console.info("jsonDocumentCurrent", jsonDocumentCurrent);
                console.info("currentHash", currentHash);

                // Get the download URL
                getDownloadURL(storageRef)
                    .then((url) => {
                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = async (event) => {
                            const blob = xhr.response;
                            const jsonDocumentNew = await blob.text();
                            console.info("Reading document at first load:", jsonDocumentNew.length, "chars");
                            //console.info("jsonDocumentNew", jsonDocumentNew);
                            if (jsonDocumentNew) {
                                const newHash = md5(jsonDocumentNew);
                                console.info("newHash", newHash);

                                if (currentHash != newHash) {
                                    console.info("UPDATED from store", currentHash, newHash);
                                    const initialEditorState = editor.parseEditorState(jsonDocumentNew);
                                    editor.setEditorState(initialEditorState);
                                    editor.setEditable(isEditableContent(story));
                                } else {
                                    console.info("No change in content", currentHash, newHash);
                                }
                            }

                            setIsFirstRender(false);
                        };
                        xhr.open('GET', url);
                        xhr.send();
                    })
                    .catch((error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        switch (error.code) {
                            case 'storage/object-not-found':
                                console.error("File doesn't exist");
                                break;
                            case 'storage/unauthorized':
                                console.error("User doesn't have permission to access the object");
                                break;
                            case 'storage/canceled':
                                console.error("User canceled the download");
                                break;
                            case 'storage/unknown':
                                console.error("Unknown error occurred, inspect the server response")
                                break;
                        }
                        console.error(error);
                    })
                    .finally(() => {
                        setIsFirstRender(false);
                    });
            }
        }, [isFirstRender, editor]);

        const lazyStorage = _.debounce((editorState) => {
            if (!isFirstRender) {
                // Read the contents of the EditorState here.
                const jsonDocument = JSON.stringify(editorState.toJSON());
                const newHash = md5(jsonDocument);
                if (newHash != jsonHash) {
                    console.info("Story & Editor State:", story.storyName, jsonDocument.length, "chars");
                    //Store file
                    const storageRef = ref_storage(storage, `stories/${story.id}.json`);
                    //https://firebase.google.com/docs/reference/js/storage#uploadstring
                    uploadString(storageRef, jsonDocument, "raw", { contentType: 'application/json' })
                        .then((snapshot) => {
                            setJsonHash(newHash);
                            console.info("Saved new content", newHash);
                            updateLastUpdateDate();
                            // Handle successful uploads on complete
                            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                            // getDownloadURL(snapshot.ref).then((downloadURL) => {
                            //     console.log('File available at', downloadURL);
                            // });
                        });
                } else {
                    console.info("Ignoring content change", newHash);
                }
            } //ensure it doesn't store before first render
        }, 1000);

        return <OnChangePlugin onChange={lazyStorage} />
    }

    const handleStoryName = (event) => {
        setStoryName(event.target.value);
    };

    const handleStoryStorage = () => {
        if (storyName != story.storyName) {
            console.info("handleStoryStorage::", storyName);
            const updates = {};
            const lastUpdated = new Date().toISOString();
            updates[`/stories/${story.id}/storyName`] = storyName;
            updates[`/stories/${story.id}/lastUpdated`] = lastUpdated;

            update(ref_database(db), updates)
                .catch((error) => {
                    console.error(`Error deleting story: "${record.storyName}"`, error);
                });
        } else {
            console.info("handleStoryStorage - no change in title");
        }
    };

    const updateLastUpdateDate = () => {
        const updates = {};
        const lastUpdated = new Date().toISOString();
        updates[`/stories/${story.id}/lastUpdated`] = lastUpdated;

        update(ref_database(db), updates);
    }

    const submitforReviewWithCoach = (row) => {
        console.info("submitforReviewWithCoach", row);
        if (row.reviewer) {
            props.submitStoryForReview(row, row.reviewer);
        } else {
            checkSubscription(user, () => {
                setRecord(row);
                setOpenCoachSelection(true);
            });
        }
    }

    const setCoachUidAndSubmit = (coachUid) => {
        console.info("setCoachUidAndSubmit", coachUid);
        setOpenCoachSelection(false);
        if (coachUid) {
            const row = record;
            setRecord(null);
            props.submitStoryForReview(row, coachUid);
        }
    }

    useEffect(() => {
        const storyRef = ref_database(db, `/stories/${story.id}`);
        const unsubscribeStory = onValue(storyRef, (storySnap) => {
            //console.info("Story:", storySnap.val());
            setStory(storySnap.val());
            if (editorRef.current) {
                const isEditable = isEditableContent(storySnap.val());
                editorRef.current.setEditable(isEditable);
            }
        });
        return (unsubscribeStory);
    }, []);

    const forceEdit = () => {
        console.info("forceEdit", story);
        if (confirm("Your coach will no longer be able to review your story. Are you sure you want to withdraw? ")) {
            const updates = {};
            const lastUpdated = new Date().toISOString();
            const status = "Draft"
            updates[`/stories/${story.id}/lastUpdated`] = lastUpdated;
            updates[`/stories/${story.id}/status`] = status;
            //updates[`/users/${story.reviewer}/reviewStories/${story.id}`] = false;
            update(ref_database(db), updates);
        }
    }

    const submitReview = () => {
        console.info("submitReview", story);
        if (confirm("Are you sure you want to send review back to author? ")) {
            const updates = {};
            const lastUpdated = new Date().toISOString();
            const status = "Draft"
            updates[`/stories/${story.id}/lastUpdated`] = lastUpdated;
            updates[`/stories/${story.id}/status`] = status;
            //updates[`/users/${story.reviewer}/reviewStories/${story.id}`] = false;
            update(ref_database(db), updates);
        }
    }

    const exportPDF = () => {
        
        const editor = editorRef.current;
        if (editor) {
            editor.update(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                console.log('htmlString', htmlString);

                var OpenWindow = window.open('','_blank','width=1024,height=768,resizable=1');
                OpenWindow.document.write(`<html><head><title>${story.storyName}</title></head><body onload="window.print()">`);
                OpenWindow.document.write(`<h1>${story.storyName}</h1>`);
                OpenWindow.document.write(htmlString);
                OpenWindow.document.write('</body></html>');
                OpenWindow.document.close();
                OpenWindow.focus();
                OpenWindow.print();
                //https://stackoverflow.com/questions/9852190/js-window-open-then-print
              });
        } else {
            alert("exportPDF count not generate HTML");
        }
    }

    function RefPlugin() {
        const [editor] = useLexicalComposerContext();
        editorRef.current = editor;
        return null;
    }

    return (
        <>
            <Stack spacing={2} direction="row" justifyContent="space-between">
                <Stack direction="row" sx={{ width: "65%" }}>
                    <IconButton onClick={props.back} color="primary">
                        <HomeIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                    <CssTextField
                        fullWidth variant="outlined"
                        value={storyName}
                        size="large"
                        onChange={handleStoryName}
                        onBlur={handleStoryStorage}
                        InputProps={{ readOnly: (!isEditableContent(story)) }} />
                </Stack>
                <Stack direction="column" sx={{ width: "35%" }}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    spacing={2}>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '25rem' }}>
                        <Typography variant="caption" noWrap>
                            {story.isDeleted && "[DELETED]"} {story.status}, updated on {formatDate(story.lastUpdated)}
                            {coach && `, coach: ${coach.displayName}`}
                        </Typography>
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        {user.uid === story.uid &&
                            <Button variant="outlined" startIcon={<GradingIcon />}
                                onClick={() => { submitforReviewWithCoach(story); }}
                                disabled={!(story.status === "Draft")}
                            >Submit</Button>
                        }
                        {user.uid === story.uid &&
                            <Button variant="outlined" startIcon={<LockOpenIcon />}
                                onClick={forceEdit}
                                disabled={!(story.status === "In-Review")}
                            >Withdraw</Button>
                        }
                        {user.uid === story.reviewer &&
                            <Button variant="outlined" startIcon={<RateReviewIcon />}
                                onClick={submitReview}
                                disabled={!(story.status === "In-Review")}
                            >Return</Button>
                        }
                        {user.uid === story.reviewer &&
                            <Button variant="outlined" startIcon={<PictureAsPdfIcon />}
                                onClick={exportPDF}
                            >Export</Button>
                        }
                    </Stack>
                </Stack>
            </Stack>
            <LexicalComposer initialConfig={editorConfig}>
                <ToolbarPlugin />
                <RichTextPlugin
                    contentEditable={<ContentEditable className="editor-input" />}
                    ErrorBoundary={LexicalErrorBoundary}
                />
                <RefPlugin />
                <FirebaseStoragePlugin />
                <HistoryPlugin />
                <CodeHighlightPlugin />
                <ListPlugin />
                <LinkPlugin />
                <AutoLinkPlugin />
                <ImagesPlugin />
                <ListMaxIndentLevelPlugin maxDepth={7} />
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            </LexicalComposer>
            <SelectCoachDialog
                coaches={props.coaches}
                open={openCoachSelection}
                onClose={setCoachUidAndSubmit}
            />
        </>
    );
}
