
export function checkSubscription(user, allowFunction) {
    if (user.hasSubscription) {
        allowFunction();
    } else {
        console.info("NO SUBSCRIPTION", user);
        alert(`Sorry, you can submit for review only with full access`);
        window.open("https://landing.mailerlite.com/webforms/landing/g5b9t8?lastname=XXX");
    }
}
